import React, { Component } from "react";
import { connect } from "react-redux";
import { withPage } from "../Page";
import * as Widget from "../Components/Widget";
import styled from "styled-components";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import Title from "../Components/Title";

class UrlNotSetPage extends Component {
  render() {
    let { siteConfig } = this.props;

    return (
      <Wrapper>
        <Widget.Col align="center">
          <Head>
            <Title firstText="Oops!" />
          </Head>
          <Container>
            <p>
              {`Can not found your tag, please use "WASHOW NetME Tag. Or buy from \n`}
              <a
                href={
                  siteConfig
                    ? siteConfig.store_url
                    : "https://www.msn.com/zh-tw"
                }
                target={"_blank"}
              >
                {siteConfig && siteConfig.store_url}
              </a>
            </p>
            <img src={"/images/failure.png"} />
          </Container>
        </Widget.Col>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  padding: 20px;
`;

const Head = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 767px) {
    width: 460px;
    align-items: flex-end;
    justify-content: space-between;
  }
  @media screen and (max-width: 479px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 460px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 479px) {
    width: 100%;
    align-items: flex-start;
  }

  & > p {
    font-size: 20px;
    color: #224e86;
    text-align: center;
    @media screen and (max-width: 479px) {
      width: 100%;
      text-align: right;
    }
  }
  & > img {
    width: 190px;
    height: 188px;
    margin-top: 80px;
  }
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      siteConfig: Selectors.getSiteConfig(state)
    }),
    ActionCreator
  )(UrlNotSetPage)
);
